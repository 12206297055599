import CartService from "../../../services/CartService";
import { PAYMENT_STATUS_CART } from "../../../helpers/constants";

export async function addCartItems(cases = []) {
  const promises = [];
  for (const case_item of cases) {
    const new_promise = new Promise(async (resolve, reject) => {
      try {
        const payload = {
          caseId: case_item.id,
          feeKind: PAYMENT_STATUS_CART[case_item?.status]?.feeKind,
        };
        const response = await CartService.createCartItem(payload);
        if (response.cartItem) {
          resolve(response.cartItem);
        }
      } catch (error) {
        reject(error);
      }
    });
    promises.push(new_promise);
  }
  return await Promise.all(promises);
}

export async function addAllCart(except = []) {
  try {
    const payload = {
      exceptCaseIds: except,
    };
    const response = await CartService.addAllCart(payload);
    if (response.cartItem) {
      return response.cartItem;
    }
  } catch (error) {
    throw error;
  }
}
export async function removeAllCart(except = []) {
  try {
    const payload = {
      exceptCaseIds: except,
    };
    const response = await CartService.removeAllCart(payload);
    if (response.cartItem) {
      return response.cartItem;
    }
  } catch (error) {
    console.log(error);
    // throw error;
  }
}

export async function removeCartItems(cases = []) {
  const promises = [];
  for (const case_item of cases) {
    const new_promise = new Promise(async (resolve, reject) => {
      try {
        const response = await CartService.removeCartItem(case_item.id);
        if (response.cartItem) {
          resolve(response.cartItem);
        }
      } catch (error) {
        reject(error);
      }
    });
    promises.push(new_promise);
  }
  return await Promise.all(promises);
}
